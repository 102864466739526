import React, { useState } from 'react';
import classNames from 'classnames';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
import { NotificationContainer } from 'react-notifications';
// import axios from 'axios';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AppContext from '../AppContext';
import Image from '../components/elements/Image';
// import Input from '../components/elements/Input';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
// import Checkbox from '../components/elements/Checkbox';
// import ButtonGroup from '../components/elements/ButtonGroup';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import Loader from "react-loader-spinner";
import Footer from '../components/layout/Footer';
import MATICSimulator from '../components/sections/MATICSimulator'
// import {
//   TwitterIcon,
//   TwitterShareButton,
// } from "react-share";

const Giveaway = ({ intl }) => {
  // const [tweetUrl, setTweetUrl] = useState('');
  // const [loading, setLoading] = useState(false);
  const [claimModalActive, setClaimModalActive] = useState(false);
  // const [agreeToTerms, setAgreeToTerms] = useState(false);
  // const [claiming, setClaiming] = useState(false);

  const outerClasses = classNames(
    'hero section center-content'
  );

  const innerClasses = classNames(
    'hero-inner section-inner'
  );

  // const validateTweetURL = (tweetUrl) => {
  //   const regex = new RegExp("https://twitter.com/(.*?)/status/(.*)$");
  //   const res = tweetUrl.match(regex);
  //   return res && res[1].length && res[2].length;
  // }

  return (
    <AppContext.Provider value={{}}>
      <NotificationContainer/>
      <section
        id="giveaways"
        className={outerClasses}
      >
        <div className="container-sm">
          <div className={innerClasses}>
            <h3><FormattedHTMLMessage id="giveaway2.title"/></h3>
            
            <div className="level-ups">
              <p>
                <Image
                  src={require(`../assets/images/giveaways/Campaign-1.gif`)}
                  alt="Level 1"
                  width={100}
                  height={100} />
                  <FormattedHTMLMessage id="giveaway.level1"/>
              </p>
              <p className="arrow">→</p>
              <p>
                <Image
                  src={require(`../assets/images/giveaways/Campaign-2.gif`)}
                  alt="Level 2"
                  width={100}
                  height={100} />
                  <FormattedHTMLMessage id="giveaway.level2"/>
              </p>
              <p className="arrow">→</p>
              <p>
                <Image
                  src={require(`../assets/images/giveaways/Campaign-3.gif`)}
                  alt="Level 3"
                  width={100}
                  height={100} />
                  <FormattedHTMLMessage id="giveaway.level3"/>
              </p>
              <p className="arrow">→</p>
              <p>
                <Image
                  src={require(`../assets/images/giveaways/Campaign-4.gif`)}
                  alt="Level 3"
                  width={100}
                  height={100} />
                  <FormattedHTMLMessage id="giveaway.level4"/>
              </p>
              <p className="arrow">→</p>
              <p>
                <Image
                  src={require(`../assets/images/giveaways/Campaign-5.gif`)}
                  alt="Level 3"
                  width={100}
                  height={100} />
                  <FormattedHTMLMessage id="giveaway.level5"/>
              </p>
            </div>

            <div className="mb-50">
              <div className="giveaway-info-inner">

              <div id="current-campaign">
                <h4><FormattedHTMLMessage id="giveaway2.title3" /></h4>
                <ul>
                  <li className="active">
                    <Image
                      src={require(`../assets/images/giveaways/Campaign-3.gif`)}
                      alt="Level 3"
                      width={100}
                      height={100} />
                    <h4><FormattedHTMLMessage id="giveaway2.event1" /></h4>
                    <p className="period"><FormattedHTMLMessage id="giveaway2.event1.period"  /></p>
                    <p><FormattedHTMLMessage id="giveaway2.event1.desc" /></p>
                  </li>
                  <li>
                    <Image
                      className="img1"
                      src={require(`../assets/images/giveaways/Campaign-1.gif`)}
                      alt="Level 1"
                      width={60}
                      height={60} />
                    <Image
                      className="img2"
                      src={require(`../assets/images/giveaways/Campaign-2.gif`)}
                      alt="Level 2"
                      width={60}
                      height={60} />
                    <h4><FormattedHTMLMessage id="giveaway2.event0" /></h4>
                    <p className="period"><FormattedHTMLMessage id="giveaway2.event0.period"  /></p>
                    <p><FormattedHTMLMessage id="giveaway2.event0.desc" /></p>
                    {/* <p><FormattedHTMLMessage id="giveaway2.event.status" values={{  }} /></p> */}
                    <span className="ended"><FormattedHTMLMessage id="giveaway2.event.ended" /></span>
                  </li>
                </ul>
              </div>
              {/* <p><FormattedHTMLMessage id="giveaway.desc"/>{' '}<FormattedHTMLMessage id="giveaway.desc1"/></p> */}
              
              <h4 className="mb-32"><FormattedHTMLMessage id="giveaway2.title1" /></h4>
              <div>
                <div className="level-ups">
                  <p>
                    <Image
                      src={require(`../assets/images/giveaways/Campaign-1.gif`)}
                      alt="Level 1"
                      width={80}
                      height={80} />
                      <FormattedHTMLMessage id="giveaway.level1"/>
                  </p>
                  <p className="arrow">→</p>
                  <p>
                    <Image
                      src={require(`../assets/images/giveaways/Campaign-2.gif`)}
                      alt="Level 2"
                      width={80}
                      height={80} />
                      <FormattedHTMLMessage id="giveaway.level2"/>
                  </p>
                  <p className="arrow">→</p>
                  <p>
                    <Image
                      src={require(`../assets/images/giveaways/Campaign-3.gif`)}
                      alt="Level 3"
                      width={80}
                      height={80} />
                      <FormattedHTMLMessage id="giveaway.level3"/>
                  </p>
                  <p className="arrow">→</p>
                  <p>
                    <Image
                      src={require(`../assets/images/giveaways/Campaign-4.gif`)}
                      alt="Level 3"
                      width={80}
                      height={80} />
                      <FormattedHTMLMessage id="giveaway.level4"/>
                  </p>
                  <p className="arrow">→</p>
                  <p>
                    <Image
                      src={require(`../assets/images/giveaways/Campaign-5.gif`)}
                      alt="Level 3"
                      width={80}
                      height={80} />
                      <FormattedHTMLMessage id="giveaway.level5"/>
                  </p>
                </div>
                <p>
                  <FormattedHTMLMessage id="giveaway2.desc1"/>
                </p>
                <p className="giveaway-warning">
                  <Button inline size="sm" onClick={() => window.open("https://matic.network/")}>
                    <Image
                      src={require(`../assets/images/matic.png`)}
                      width={150} />
                  </Button>
                  <span> <FormattedHTMLMessage id="giveaway.warn" /></span>
                </p>
              </div>

              <h4 className="mt-80 mb-32"><FormattedHTMLMessage id="giveaway2.title2" /></h4>
              <div className="giveaway-tasks">
                <p>
                  <FormattedHTMLMessage id="giveaway2.desc2"/>
                </p>
                <ul>
                  <li>
                    <p><Image src={require(`../assets/images/giveaways/task1.gif`)} /></p>
                    <div><p><FormattedHTMLMessage id="giveaway2.task-1" /></p></div>
                  </li>
                  <li>
                    <p><Image src={require(`../assets/images/giveaways/task2.gif`)} /></p>
                    <div><p><FormattedHTMLMessage id="giveaway2.task-2" /></p></div>
                  </li>
                  <li>
                    <p><Image src={require(`../assets/images/giveaways/task3.gif`)} /></p>
                    <div><p><FormattedHTMLMessage id="giveaway2.task-3" /></p></div>
                  </li>
                  <li>
                    <p><Image src={require(`../assets/images/giveaways/task4.gif`)} /></p>
                    <div><p><FormattedHTMLMessage id="giveaway2.task-4" /></p></div>
                  </li>
                  <li>
                    <p><Image src={require(`../assets/images/giveaways/task5.gif`)} /></p>
                    <div><p><FormattedHTMLMessage id="giveaway2.task-5" /></p></div>
                  </li>
                </ul>
                <MATICSimulator />
              </div>
              
              {/* <Tabs>
                <TabList>
                  <Tab><FormattedHTMLMessage id="giveaway2.tab1"/></Tab>
                  <Tab><FormattedHTMLMessage id="giveaway2.tab2"/></Tab>
                  <Tab><FormattedHTMLMessage id="giveaway2.tab3"/></Tab>
                  <Tab><FormattedHTMLMessage id="giveaway2.tab4"/></Tab>
                </TabList>

                <TabPanel>
                  <h4><FormattedHTMLMessage id="giveaway2.step1"/>
                    <span className="badge-claim"><FormattedHTMLMessage id="giveaway2.claim-badge" defaultMessage="Claim"/></span>
                    <span className="badge-upgrade"><FormattedHTMLMessage id="giveaway2.upgrade-badge" defaultMessage="Upgrade"/></span>
                  </h4>
                  <div className="step-twitter mb-20">
                    <ul>
                      <li><FormattedHTMLMessage id="giveaway2.step1.1"/></li>
                      <li><FormattedHTMLMessage id="giveaway2.step1.2"/></li>
                      <li>
                        <div>
                          <p className="tweet-button">
                            <TwitterShareButton url="rocbabies.io" title={intl.formatMessage({ id: 'giveaway2.quotetweet' })}>
                              <TwitterIcon size={30} />
                              <FormattedHTMLMessage id="giveaway2.quotetweethere"/>
                            </TwitterShareButton>
                          </p>
                          <p className="tweet-button-desc"><FormattedHTMLMessage id="giveaway2.step1.3-2"/></p>
                        </div>
                      </li>
                      <li>
                        <FormattedHTMLMessage id="giveaway2.step1.4"/>
                        <div className="where-to-find">
                          <p id="tweeturl" className="sub3"><FormattedHTMLMessage id="giveaway2.step1.sub3"/></p>
                          <div className="tw-ex">
                            <p className="pc">
                              <Image src={require(`../assets/images/tw1.jpg`)} />
                              <FormattedHTMLMessage id="giveaway2.step1.pc"/>
                            </p>
                            <p className="mobile">
                              <Image src={require(`../assets/images/tw2.jpg`)} />
                              <FormattedHTMLMessage id="giveaway2.step1.mobile"/>
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <a rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLScBcLGtOaNRbnLCrpOLEwh4iM8NH6XkMahtj1hRhGPczOX8IA/viewform" target="_blank">
                          MATIC Giveaway Claim form for Twitter
                        </a>
                      </li>
                    </ul>
                    <ButtonGroup className="giveaway-buttons">
                      <Input className="giveaway-input" value={tweetUrl} onChange={(e) => { 
                        setTweetUrl(e.target.value)
                      }} type="text" placeholder="https://twitter.com/[username]/status/[tweetid]"></Input>
                      <Button color="primary" disabled={loading || !validateTweetURL(tweetUrl)} onClick={async () => {
                        setClaimModalActive(true);
                      }}>
                        {loading && <Loader
                          type="TailSpin"
                          color="#ffffff"
                          height={22}
                          width={22}
                        />}
                        <FormattedHTMLMessage id="giveaway2.claim" />
                      </Button>
                    </ButtonGroup>
                    <div className="tweet-rule">
                      <p className="sub1"><FormattedHTMLMessage id="giveaway2.step1.sub1"/></p>
                      <p className="sub2"><FormattedHTMLMessage id="giveaway2.step1.sub2"/></p>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <h4>
                    <FormattedHTMLMessage id="giveaway2.step2" />
                    <span className="badge-claim"><FormattedHTMLMessage id="giveaway2.claim-badge" defaultMessage="Claim"/></span>
                    <span className="badge-upgrade"><FormattedHTMLMessage id="giveaway2.upgrade-badge" defaultMessage="Upgrade"/></span>
                  </h4>
                  <div className="step-discord mb-20">
                    <ul>
                      <li><FormattedHTMLMessage id="giveaway2.step2.1" /></li>
                      <li>
                        <FormattedHTMLMessage id="giveaway2.step2.2" />
                        <Image
                          src={require(`../assets/images/giveaways/discord-invite-1.png`)}
                          width={400} />
                      </li>
                      <li>
                        <FormattedHTMLMessage id="giveaway2.step2.3" />
                      </li>
                      <li>
                        <FormattedHTMLMessage id="giveaway2.step2.4" />
                        <Image
                          src={require(`../assets/images/giveaways/discord-invite-2.png`)}
                          width={400} />
                      </li>
                    </ul>
                  </div>
                </TabPanel>
                <TabPanel>
                  <h4>
                    <FormattedHTMLMessage id="giveaway2.step3" />
                    <span className="badge-claim"><FormattedHTMLMessage id="giveaway2.claim-badge" defaultMessage="Claim"/></span>
                    <span className="badge-upgrade"><FormattedHTMLMessage id="giveaway2.upgrade-badge" defaultMessage="Upgrade"/></span>
                  </h4>
                  <div className="step-discord mb-80">
                    <ul>
                      <li>
                        <FormattedHTMLMessage id="giveaway2.step3.1" />
                        <Image
                          src={require(`../assets/images/giveaways/giveaway-w3.png`)}
                          width={400}
                        />
                      </li>
                    </ul>
                  </div>
                </TabPanel>

                <TabPanel>
                  <h4>
                    <FormattedHTMLMessage id="giveaway2.step4" />
                    <span className="badge-upgrade"><FormattedHTMLMessage id="giveaway2.upgradeonly-badge" defaultMessage="Upgrade"/></span>
                  </h4>
                  <div className="step-discord mb-80">
                    <ul>
                      <li>
                        <FormattedHTMLMessage id="giveaway2.step4.1" />
                        <Image
                          src={require(`../assets/images/giveaways/giveaway-w4.png`)}
                          width={400}
                        />
                      </li>
                    </ul>
                  </div>
                </TabPanel>

                <TabPanel>
                  
                </TabPanel>
              </Tabs> */}
              
              <div className="giveaway-warning2 mb-80">
                <span>
                  <FormattedHTMLMessage id="giveaway2.warn" />
                </span>
              </div>

            </div>
            </div>
          </div>
        </div>

        <Modal
          id="confirmation"
          show={claimModalActive}
          handleClose={() => setClaimModalActive(false)}>
            <h4><FormattedHTMLMessage id="claim.title" /></h4>
            <div className="giveaway-info-inner">
              {/* <div><FormattedHTMLMessage id="claim.terms" /></div> */}
              <p><FormattedHTMLMessage id="claim.desc" /></p>
            </div>

            {/* <div className="agree-checkbox">
              <Checkbox name="agree" onClick={(e) => { setAgreeToTerms(e.target.checked) }} >
                <b><FormattedHTMLMessage id="I agree to the terms and conditions" /></b>
              </Checkbox>
            </div> */}

            {/* <ButtonGroup className="buyingbox">
              <Button color="primary" wideMobile disabled={claiming || !agreeToTerms} onClick={async () => {
                setClaiming(true);
                setLoading(true);
                window.gtag('event', 'claim', {
                  'event_category' : 'engagement',
                  'event_label' : 'Claim'
                })
                try {
                  // const claim = await axios.post(`http://localhost:3000/claim/tweet/`, {
                  const claim = await axios.post(`https://api-giveaway.rocbabies.io/claim/tweet/`, {
                    tweeturl: tweetUrl
                  });
                  if (claim) {
                    NotificationManager.success(`Giveaway token was successfully transferred🎁. Thank you for your support! ${claim.data}`);
                  }
                } catch(e) {
                  NotificationManager.error((e.response && e.response.data) || e.message);
                }
                setClaimModalActive(false);
                setClaiming(false);
                setLoading(false);
                
              }
              }>
                <FormattedHTMLMessage id="giveaway2.claim" />
              </Button>
            </ButtonGroup> */}
        </Modal>
      </section>
      <Footer />
    </AppContext.Provider>
  );
}

export default injectIntl(Giveaway);