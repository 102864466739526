import { ethers } from 'ethers';
import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import AppContext from '../../AppContext';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Input from '../elements/Input';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Checkbox from '../elements/Checkbox';
import { connectWallet, getContract } from '../../ethereum';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import FollowAt from "../elements/react-social-media-follow";
import Slider from "react-slick";
import * as Sentry from "@sentry/react";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  intl,
  startSales,
  startGiveaways,
  mobile,
  ...props
}) => {
  const [thankYouModalActive, setThankYouModalActive] = useState(false);
  const [priceStrModalActive, setPriceStrModalActive] = useState(false);
  const [giveAwayModalActive, setGiveAwayModalActive] = useState(false);
  const [purchaseModalActive, setPurchaseModalActive] = useState(false);
  const [babiesWriteContract, setBabiesWriteContract] = useState(false);
  const [wrongNetwork, setWrongNetwork] = useState(false);
  const [showCheckout, setShowCheckout] = useState(true);

  // const [contractInfo, setContractInfo] = useState(false)
  const [buying, setBuying] = useState(false);
  const [numBabies, setNumBabies] = useState(1);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [agreeToTerms2, setAgreeToTerms2] = useState(false);
  const { contractInfo, glitch } = useContext(AppContext);
  // const [address, setAddress] = useState(false);

  const closeModal = (e) => {
    e.preventDefault();
    setThankYouModalActive(false);
    setPriceStrModalActive(false);
    setGiveAwayModalActive(false);
    setPurchaseModalActive(false);
    setAgreeToTerms(false);
    setAgreeToTerms2(false);
  }

  // Connect to Ethereum
  useEffect(() => {
    const init = async () => {
      if (!startSales) return;

      // Babies Contract
      try {
        const { babiesContract, isWrongNetwork } = await getContract();
        if (babiesContract) {
          setBabiesWriteContract(babiesContract);
        }
        setWrongNetwork(isWrongNetwork);
      } catch(e) {
        console.error(e.message);
      }
    };
    init();
  }, [startSales]);
  
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  // const innerClasses = classNames(
  //   'hero-inner section-inner',
  //   topDivider && 'has-top-divider',
  //   bottomDivider && 'has-bottom-divider'
  // );

  return (
    <>
      <section
        {...props}
        className={outerClasses}
      >
        {startGiveaways && <div className="campaign-pane">
          <Button className="giveawayButton" size="sm" onClick={() => { window.location.href = "/giveaways" }}>
            <h5><FormattedHTMLMessage id="giveaway.title" /></h5>
            <Image
              className="has-shadow"
              src={require(`../../assets/images/giveaways/Campaign-3.gif`)}
              alt="Campaign Baby"
              width={100}
              height={100} />
          </Button>
        </div> }

        <div className="container-sm">
          <div>
            <div className="hero-contentxxxxx">
              <h1 className="mt-0 reveal-from-bottom" data-reveal-delay="200">
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/logo.png')}
                  alt="rocbabies"
                  width={600} />
              </h1>
              <div className="container-sm">
                <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  <FormattedHTMLMessage id="caption" />
                </p>
                <p className="contract-address">
                  <FormattedHTMLMessage id="contactAddress" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">            
            <Slider
              arrow={false}
              dots={false}
              fade={true}
              infinite={true}
              speed={1000}
              autoplay={true}
              autoplaySpeed={2000}
              slidesToShow={1}
              slidesToScroll={1}
            >
              <div>
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/collage/collage1.gif')}
                  alt="Hero"
                  width={2500}
                  height={250} />
              </div>
              <div>
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/collage/collage2.gif')}
                  alt="Hero"
                  width={2500}
                  height={250} />
              </div>
              <div>
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/collage/collage3.gif')}
                  alt="Hero"
                  width={2500}
                  height={250} />
              </div>
              <div>
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/collage/collage4.gif')}
                  alt="Hero"
                  width={2500}
                  height={250} />
              </div>
              <div>
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/collage/collage5.gif')}
                  alt="Hero"
                  width={2500}
                  height={250} />
              </div>
              <div>
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/collage/collage6.gif')}
                  alt="Hero"
                  width={2500}
                  height={250} />
              </div>
              <div>
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/collage/collage7.gif')}
                  alt="Hero"
                  width={2500}
                  height={250} />
              </div>
              <div>
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/collage/collage8.gif')}
                  alt="Hero"
                  width={2500}
                  height={250} />
              </div>
              <div>
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/collage/collage9.gif')}
                  alt="Hero"
                  width={2500}
                  height={250} />
              </div>
              <div>
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/collage/collage10.gif')}
                  alt="Hero"
                  width={2500}
                  height={250} />
              </div>
              <div>
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/collage/collage11.gif')}
                  alt="Hero"
                  width={2500}
                  height={250} />
              </div>
          </Slider>
        </div>
        <div className="fixed-footer" data-reveal-delay="600">
          {/* {mobile && <p className="for-mobile"><FormattedHTMLMessage id="mobile" /></p>} */}
          <div className="copy">
            <p>© RocBabies, 2021</p>
          </div>
          <div className="checkout-panel">
            {mobile && showCheckout && <button
              className="modal-close"
              aria-label="close"
              onClick={() => setShowCheckout(false)}
            ></button>}
            {glitch && <p className="text-color-error"><FormattedHTMLMessage id="something" /></p>}
            <ButtonGroup>
              {showCheckout && babiesWriteContract && contractInfo && (
                <>
                  { wrongNetwork && <div className="has-sale-started"><p><FormattedHTMLMessage id="wrong-network" /></p></div> }
                  { !wrongNetwork && contractInfo.totalSupply >= contractInfo.maximumBabies && <div className="has-sale-started"><p><FormattedHTMLMessage id="sale-ended" /></p></div> }
                  { !contractInfo.hasSaleStarted && contractInfo.totalSupply !== contractInfo.maximumBabies && <div className="has-sale-started"><p><FormattedHTMLMessage id="sale-hasntstarted" /></p></div> }
                  <div className="mr-8 before-after-small">
                      <p>
                        <Image
                          src={require(`../../assets/images/incubator.png`)}
                          alt="Open"
                          width={100}
                          height={100} />
                          <FormattedHTMLMessage id="before" />
                      </p>
                      <p className="arrow">→</p>
                      <p>
                        <Image
                          src={require(`../../assets/images/babies/random.gif`)}
                          alt="Open"
                          width={100}
                          height={100} />
                          <FormattedHTMLMessage id="after" />
                      </p>
                  </div>
                  <div className="mr-8">
                    <Input
                      value={numBabies}
                      onChange={(e) => {
                        let num;
                        const currentMax = contractInfo.maximumBabies - contractInfo.totalSupply;
                        if (currentMax < 20) {
                          num = e.target.value < currentMax ? Math.floor(e.target.value) : currentMax;
                        } else {
                          num = e.target.value < 20 ? Math.floor(e.target.value) : 20;
                        }
                        setNumBabies(`${Number(num)}`)
                      }} id="maxBabies"
                      type="number"
                      max="20" 
                      min="1" 
                      step="1"
                      onKeyPress={(e) => {
                        return e.charCode >= 48 && e.charCode <= 57
                      }}
                      label={intl.formatMessage({ id: "howmany" })} 
                      hint={ intl.formatMessage({ id: "max" })} 
                      placeholder="0"
                      disabled={!contractInfo.hasSaleStarted}
                    >
                    </Input>
                  </div>
                  <div className="mr-8">
                    <Input disabled value={`${(numBabies * contractInfo.currentPrice).toFixed(3)} Ether`} hint={ intl.formatMessage({ id: "plusgas" })} label={ intl.formatMessage({ id: "cost" })} type="text"></Input>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      wideMobile
                      disabled={buying || !contractInfo.currentPrice || !contractInfo.hasSaleStarted }
                      onClick={async () => {
                        setPurchaseModalActive(true)
                      }}
                    >
                      <FormattedHTMLMessage id="checkout" />
                    </Button>
                  </div>
                </>
              )}
              {startSales && !babiesWriteContract && (
                <Button className="connect" color="primary" wideMobile onClick={async () => {
                  
                  window.gtag('event', 'connect', {
                    'event_category' : 'engagement',
                    'event_label' : 'Metamask Connect'
                  })

                  try {
                    const { babiesContract } = await connectWallet();
                    setBabiesWriteContract(babiesContract); 
                  } catch(e) {
                    console.error(e.message)
                  }
                }}>
                  <FormattedHTMLMessage id="connectmm" />
                </Button>
              )}
              {mobile && !showCheckout && (
                <Button className="connect" color="primary" wideMobile onClick={async () => {
                  setShowCheckout(true)
                }}>
                  <FormattedHTMLMessage id="showCheckout" />
                </Button>
              )}
              {/* <Button color="dark" wideMobile href="https://github.com/cruip/open-react-template/">
                View on Github
              </Button> */}
            </ButtonGroup>

            {contractInfo && (
              <div className="footer-info">
                <p><span className="ttl">{intl.formatMessage({ id: "adopted"})}</span> <span className="val">{contractInfo.maximumBabies - contractInfo.totalSupply - 1}</span></p>
                <p><span className="ttl">{intl.formatMessage({ id: "currentprice"})}</span> <span className="val">㆔{contractInfo.currentPrice}</span></p>
                {!mobile && <Button inline size="sm" onClick={() => {
                  setPriceStrModalActive(true)
                }}><FormattedHTMLMessage id="prices" />
                </Button>}
                {/* <p>hasSaleStarted: {contractInfo.hasSaleStarted ? 'Yes' : 'No'}</p> */}
              </div>
            )}
          </div>
          <div className="followat">
            <FollowAt links = {[
              'https://twitter.com/rocbabies',
              'https://discord.gg/zE78aDppkG',
            ]}/>
          </div>
        </div>
      </section>
      <Modal
        id="thankyouModal"
        show={thankYouModalActive}
        handleClose={closeModal}>
          <h4><FormattedHTMLMessage id="thank" /></h4>
          <div>
            <p><FormattedHTMLMessage id="thank.1" /></p>
            <p><FormattedHTMLMessage id="thank.2" /></p>
            <p><FormattedHTMLMessage id="thank.3" /></p>
            <div className="before-after">
              <p>
                <Image
                  src={require(`../../assets/images/incubator.png`)}
                  alt="Open"
                  width={200}
                  height={200} />
                  <FormattedHTMLMessage id="before" />
              </p>
              <p className="arrow">→</p>
              <p>
                <Image
                  src={require(`../../assets/images/babies/random.gif`)}
                  alt="Open"
                  width={200}
                  height={200} />
                  <FormattedHTMLMessage id="after" />
              </p>
            </div>
          </div>
      </Modal>

      <Modal
        id="price-structure"
        show={priceStrModalActive}
        handleClose={closeModal}>
          <h5><FormattedHTMLMessage id="prices" /></h5>
          <div>
            <p><FormattedHTMLMessage id="prices.desc" /></p>
            <ul className="current-price">
              <li className={`${contractInfo.totalSupply < 4999 ? 'active': ''}`}>
                <span className="title"><FormattedHTMLMessage id="prices.title1" /></span>
                <p>
                  <Image
                    src={require(`../../assets/images/early-baby.gif`)}
                    alt="Open"
                    width={100}
                    height={100} />
                  <span className="price"><FormattedHTMLMessage id="prices.1" /></span>
                </p>
              </li>
              <li className={`${contractInfo.totalSupply < 4999 ? '': 'active'}`}>
                <span className="title"><FormattedHTMLMessage id="prices.title2" /></span>
                <p>
                  <Image
                    src={require(`../../assets/images/late-baby.gif`)}
                    alt="Open"
                    width={100}
                    height={100} />
                  <span className="price"><FormattedHTMLMessage id="prices.2" /></span>
                </p>
              </li>
            </ul>
            <p className="text-color-warning"><FormattedHTMLMessage id="prices.3" /></p>
          </div>
      </Modal>
      <Modal
        id="giveaway-info"
        show={giveAwayModalActive}
        handleClose={closeModal}>
          <h4><FormattedHTMLMessage id="giveaway.title" /></h4>
          <div className="giveaway-info-inner">
            <div className="before-after">
              <p>
                <Image
                  src={require(`../../assets/images/giveaways/Campaign-1.gif`)}
                  alt="Open"
                  width={200}
                  height={200} />
                  <FormattedHTMLMessage id="giveaway.level1" />
              </p>
              <p className="arrow">→</p>
              <p>
                <Image
                  src={require(`../../assets/images/giveaways/Campaign-2.gif`)}
                  alt="Open"
                  width={200}
                  height={200} />
                  <FormattedHTMLMessage id="giveaway.level2" />
              </p>
              <p className="arrow">→</p>
              <p>
                <Image
                  src={require(`../../assets/images/giveaways/Campaign-3.gif`)}
                  alt="Open"
                  width={200}
                  height={200} />
                  <FormattedHTMLMessage id="giveaway.level3" />
              </p>
              <p className="arrow">→</p>
              <p>
                <Image
                  src={require(`../../assets/images/giveaways/Campaign-4.gif`)}
                  alt="Open"
                  width={200}
                  height={200} />
                  <FormattedHTMLMessage id="giveaway.level4" />
              </p>
              <p className="arrow">→</p>
              <p>
                <Image
                  src={require(`../../assets/images/giveaways/Campaign-5.gif`)}
                  alt="Open"
                  width={200}
                  height={200} />
                  <FormattedHTMLMessage id="giveaway.level5" />
              </p>
            </div>
            <p><FormattedHTMLMessage id="giveaway.desc" /></p>
            {/* <p><FormattedHTMLMessage id="giveaway.desc1" /></p> */}
            {/* <p><FormattedHTMLMessage id="giveaway.desc2" /></p> */}
            {/* <div className="mb-40">
              <ol>
                <li><FormattedHTMLMessage id="giveaway.step1" /></li>
                <li><FormattedHTMLMessage id="giveaway.step2" /></li>
                <li><FormattedHTMLMessage id="giveaway.step3" /></li>
              </ol>
            </div> */}
            <p className="text-color-info tx-c"><a href="/giveaways"><b><FormattedHTMLMessage id="giveaway.link" /></b></a></p>
            {/* <p className="text-color-warning"><b><FormattedHTMLMessage id="giveaway.warn" /></b></p> */}
          </div>
      </Modal>

      <Modal
        id="purchase-confirmation"
        show={purchaseModalActive}
        handleClose={closeModal}>
          <h4><FormattedHTMLMessage id="buy.title" /></h4>
          <div className="giveaway-info-inner">
            <div><FormattedHTMLMessage id="buy.terms" /></div>
            {/* <p className="text-color-warning"><FormattedHTMLMessage id="howtobuy.warn" /></p> */}
          </div>

          <div className="agree-checkbox">
            <p><Checkbox name="agree" onClick={(e) => { setAgreeToTerms(e.target.checked) }} >
              <b><FormattedHTMLMessage id="buy.agree1" /></b>
            </Checkbox></p>
            <p><Checkbox name="agree" onClick={(e) => { setAgreeToTerms2(e.target.checked) }} >
              <b><FormattedHTMLMessage id="buy.agree2" /></b>
            </Checkbox></p>
          </div>

          <ButtonGroup className="buyingbox">
            <FormattedHTMLMessage id="buy.buying" values={{ numBabies, cost: `${(numBabies * contractInfo.currentPrice).toFixed(3)} Ether` }} />  
            {/* <div className="mr-8"><Input value={numBabies} onChange={(e) => { 
              setNumBabies(e.target.value < 20 ? e.target.value : 20) 
            }} id="maxBabies" type="number" max="20" min="1" label={intl.formatMessage({ id: "howmany" })} hint={ intl.formatMessage({ id: "max" })} placeholder="0"></Input></div>
            <div className="mr-8"><Input disabled value={`${(numBabies * contractInfo.currentPrice).toFixed(3)} Ether`} hint={ intl.formatMessage({ id: "plusgas" })} label={ intl.formatMessage({ id: "Cost" })} type="text"></Input></div> */}

            <Button color="primary" wideMobile disabled={buying || !agreeToTerms || !agreeToTerms2} onClick={async () => {

              window.gtag('event', 'purchase confirm', {
                'event_category' : 'engagement',
                'event_label' : 'Purchase',
                "mobile": mobile ? 'true': 'false'
              })

              try{
                Sentry.captureMessage("Purchase Confirm", {
                  level: "info",
                });
              }catch(e) {
                console.log(e.message);
              }

              try{

                setBuying(true);
                const estimate = await babiesWriteContract.estimateGas.adoptBabies(numBabies, {
                  value: ethers.utils.parseEther((numBabies * contractInfo.currentPrice).toFixed(3))
                })
                const res = await babiesWriteContract.adoptBabies(numBabies, {
                  value: ethers.utils.parseEther((numBabies * contractInfo.currentPrice).toFixed(3)),
                  gasLimit: Math.ceil(estimate.toNumber()*1.3),
                })
                setPurchaseModalActive(false);
                if (res.hash) {
                  window.gtag('event', 'purchase complete', {
                    'event_category' : 'engagement',
                    'event_label' : 'Purchase Complete',
                    'transaction': res.hash,
                    "mobile": mobile ? 'true': 'false'
                  })
                  NotificationManager.info(`Hash: ${res.hash}`, `Your transaction has been sent.`, 10000, () => {
                    window.open(`https://etherscan.io/tx/${res.hash}`)
                  });
                  
                  try {
                    Sentry.captureMessage(new Error("Purchase Complete"), {
                      level: "info",
                    });
                  } catch (e) {}
                  
                  setThankYouModalActive(true);
                  setBuying(false);

                  try {
                    await axios.post(`https://srb-giveaway-whitelist.herokuapp.com/claim/tx/`, {
                      tx: res.hash,
                    });
                  } catch (e) {}
                }
              }catch(e){
                if (e.error && e.error.data && e.error.data.originalError) {
                  Sentry.captureException(new Error(e.error.data.originalError.message));
                  NotificationManager.error(e.error.data.originalError.message)
                } else {
                  Sentry.captureException(new Error(e.message));
                  NotificationManager.error(e.message)
                }
                window.gtag('event', 'purchase error', {
                  'event_category' : 'engagement',
                  'event_label' : 'Purchase Error',
                  'error': e.message,
                  "mobile": mobile ? 'true': 'false'
                })
                setPurchaseModalActive(false)
                setBuying(false);
              }
            }
            }>
              <FormattedHTMLMessage id="buy" />
            </Button>
          </ButtonGroup>
      </Modal>
    </>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default injectIntl(Hero);