import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { FormattedHTMLMessage } from 'react-intl';
import Image from '../elements/Image'
import Button from '../elements/Button'
import ButtonGroup from '../elements/ButtonGroup'

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  startGiveaways,
  setLang,
  lang,
  ...props
}) => {

  const [isActive, setIsactive] = useState(false);
  const location = useLocation();
  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });  

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }  

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );

  return (
    <header
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider'
          )}>
          {!hideNav &&
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={
                  classNames(
                    'header-nav',
                    isActive && 'is-active'
                  )}>
                <div className="header-nav-inner">
                  <ul className={
                    classNames(
                      'list-reset text-xs',
                      navPosition && `header-nav-${navPosition}`
                    )}>
                    {/* <h1 className="mt-0 reveal-from-bottom" data-reveal-delay="200">
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/logo_s.png')}
                        alt="rocbabies"
                        width={100} />
                    </h1> */}
                    <li className={`${location.pathname === '/' ? 'active': ''}`}>
                      <Link to="" onClick={closeMenu}><b><FormattedHTMLMessage id="link1" /></b></Link>
                    </li>
                    {startGiveaways && <li className={`${location.pathname === '/giveaways' ? 'active': ''}`}>
                      <Link to="giveaways" onClick={closeMenu}><b><FormattedHTMLMessage id="link2" /></b></Link>
                    </li>}
                    <li className={`${location.pathname === '/owners' ? 'active': ''}`}>
                      <Link to="owners" onClick={closeMenu}><b><FormattedHTMLMessage id="link5" /></b></Link>
                    </li>
                    <li className={`${location.pathname === '/faqs' ? 'active': ''}`}>
                      <Link to="faqs" onClick={closeMenu}><b><FormattedHTMLMessage id="link4" /></b></Link>
                    </li>
                    <li className={`${location.pathname === '/terms' ? 'active': ''}`}>
                      <Link to="terms" onClick={closeMenu}><b><FormattedHTMLMessage id="link3" /></b></Link>
                    </li>
                    <li className="lang-switch">
                      <ButtonGroup>
                        <Button size="sm" className="en" disabled={lang==='en'} onClick={() => setLang('en')}>
                          <Image
                            src={require(`../../assets/images/lang/en.png`)}
                            alt="Open"
                            width={20}
                            height={10} />
                        </Button>
                        <Button size="sm" className="ja" disabled={lang==='ja'} onClick={() => setLang('ja')}>
                          <Image
                            src={require(`../../assets/images/lang/ja.png`)}
                            alt="Open"
                            width={20}
                            height={10} />
                        </Button>
                      </ButtonGroup>
                    </li>
                  </ul>
                </div>
              </nav>
            </>}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
