import React, { useState, useEffect} from 'react';
import classNames from 'classnames';
import { FormattedHTMLMessage,injectIntl } from 'react-intl';
import AppContext from '../AppContext';
import Footer from '../components/layout/Footer';
import Button from '../components/elements/Button';
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { connectWallet, getContract } from '../ethereum';
import axios from 'axios';

const outerClasses = classNames(
  'hero section center-content'
);

const innerClasses = classNames(
  'hero-inner section-inner owners'
);

const Owners = ({
  startSales,
  ...props
}) => {
  const [babiesWriteContract, setBabiesWriteContract] = useState(false);
  const [wrongNetwork, setWrongNetwork] = useState(false);
  const [address, setAddress ] = useState('');
  const [babies, setBabies] = useState([]);
  const [loading, setLoading] = useState(false)
  const [verifiedDiscordID, setVerifiedDiscordID] = useState(false)
  const [discordID, setDiscordID] = useState('');
  
  // Connect to Ethereum
  useEffect(() => {
    const init = async () => {
      if (!startSales) return;
      // Babies Contract
      try {
        const { babiesContract, address, isWrongNetwork } = await getContract();
        if (babiesContract) {
          setBabiesWriteContract(babiesContract);
          setAddress(address);
        }
        setWrongNetwork(isWrongNetwork);
      } catch(e) {
        console.error(e.message);
      }
    };
    init();
  }, [startSales]);

  // Connect to Ethereum
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      let result;
      try {
        result = await babiesWriteContract.tokensOfOwner(address);
        const babiesJsons = [];

        if (result) {
          for(let i=0; i < result.length; i++) {
            let token;
            try {
              token = await axios.get(`https://raw.githubusercontent.com/rocbabies/rocbabies/master/tokens/${result[i].toString()}`);
              babiesJsons.push(token.data);
            } catch(e) {
              NotificationManager.error(e.message)
            }
          }
          setBabies([...babiesJsons])
        }
      } catch(e) {
        NotificationManager.error(e.message)
      }

      let discordID;
      try {
        discordID = await axios.get(`https://srb-giveaway-whitelist.herokuapp.com/verify?address=${address.toLocaleLowerCase()}`)
      } catch(e) {
        NotificationManager.error(e.message)
      }

      if (discordID && discordID.data && discordID.data.length) {
        setVerifiedDiscordID(discordID.data[0]);
      }
      setLoading(false);
    }
    if (babiesWriteContract && address) {
      init()
    }
    console.log(babiesWriteContract, 'babiesWriteContract')
  }, [babiesWriteContract, address]);

  

  return (
    <AppContext.Provider value={{}}>
      <section
        className={outerClasses}
        id="owners"
      >
        <div className={innerClasses}>
          <h3><FormattedHTMLMessage id="owners" /></h3>
          {/* {babies && !!babies.length && <p><FormattedHTMLMessage id="owners.desc1" /></p> } */}

          <div className="mt-32">
            {wrongNetwork && (
              <div><FormattedHTMLMessage id="wrong-network" /></div>
            ) }
            {!wrongNetwork && (
              <>
                <div>
                  {startSales && !babiesWriteContract && (
                    <Button className="connect" color="primary" wideMobile onClick={async () => {
                      
                      window.gtag('event', 'connect', {
                        'event_category' : 'engagement',
                        'event_label' : 'Metamask Connect'
                      })

                      try {
                        const { babiesContract, address, isWrongNetwork } = await connectWallet();
                        if (babiesContract) {
                          setBabiesWriteContract(babiesContract);
                          setAddress(address);
                        }
                        setWrongNetwork(isWrongNetwork);
                      } catch(e) {
                        console.error(e.message)
                      }
                    }}>
                      <FormattedHTMLMessage id="connectmm" />
                    </Button>
                  )}
                </div>
                { loading && <div>Loading...</div>}
                { !loading && babiesWriteContract && (
                  <div className="profile">
                      {babies && !!babies.length && (
                        <>
                          {address && <div className="address">
                            <h5><FormattedHTMLMessage id="owners.address" /></h5> <p>{[...address.slice(0, 10),'....',...address.slice(-10)]}</p>
                          </div> }
                          <div className="discord">
                            <h5><FormattedHTMLMessage id="owners.discord" /></h5> 
                            {verifiedDiscordID ? (
                              <div>{verifiedDiscordID.discordID}</div>
                            ) : (
                              <>
                                <Input size="sm" type="text" labelHidden placeholder="username#ID" value={discordID} onChange={(e)=>{
                                  setDiscordID(e.target.value)
                                }} />
                                <Button size="sm" color="primary" onClick={async () => {
                                  let res;
                                  try {
                                    res = await axios.post('https://srb-giveaway-whitelist.herokuapp.com/verify', {
                                      discordID,
                                      address,
                                    });
                                    setVerifiedDiscordID({ discordID: res.data });
                                    NotificationManager.info('Successfully registered.')
                                  } catch(e) {
                                    NotificationManager.error(e.message)
                                  }
                                }}><FormattedHTMLMessage id="owners.discord.register" /></Button>
                              </>
                            )}
                          </div>
                          {!verifiedDiscordID && <p><FormattedHTMLMessage id="owners.discord.desc" /></p>}
                        </>
                      )}

                  </div>
                )}
                { !loading && babiesWriteContract && babies && !babies.length && (
                  <div className="token-list mt-32">
                    <FormattedHTMLMessage id="owners.nobabies" />
                  </div>
                )}
                { !loading && babiesWriteContract  && !!babies.length && (
                  <div className="token-list mt-32">
                    {/* <h4><FormattedHTMLMessage id="owners.babies" /></h4> */}
                    <ul>
                      {babies.map((token) => (
                        <li>
                          <Image src={token.image} alt={token.name} width="100" height="100" />
                          <span>{token.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) }
              </>
            )}
          </div>
        </div>
      </section>
      <Footer />
      <NotificationContainer/>
    </AppContext.Provider>
  );
}

export default injectIntl(Owners);