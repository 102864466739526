import React from 'react';
import classNames from 'classnames';
import { FormattedHTMLMessage,injectIntl } from 'react-intl';
import AppContext from '../AppContext';
import Footer from '../components/layout/Footer';
import Image from '../components/elements/Image';

const outerClasses = classNames(
  'hero section center-content'
);

const innerClasses = classNames(
  'hero-inner section-inner faqs'
);

const FAQs = (props) => {
  const { intl } = props;

  return (
    <AppContext.Provider value={{}}>
      <section
        className={outerClasses}
        id="faqs"
      >
        <div className={innerClasses}>
          <h2><FormattedHTMLMessage id="faq" /></h2>
        </div>


        <div>
          <h3><FormattedHTMLMessage id="faq.matic" /></h3>
          <ul>
            {Object.keys(intl.messages).filter((k) => (k.includes('faqMatic.q'))).map((key) => {
              const qIndex = key.replace('faqMatic.q', '');
              return (
                <li>
                  <h4><span>Q.</span><FormattedHTMLMessage id={`faqMatic.q${qIndex}`} /></h4>
                  <p><FormattedHTMLMessage id={`faqMatic.a${qIndex}`} /></p>
                  {intl.messages[`faqMatic.img${qIndex}`] && (
                    <Image width="300" src={require(`../assets/images/faqs/${intl.messages[`faqMatic.img${qIndex}`]}`)} />
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      </section>
      <Footer />
    </AppContext.Provider>
  );
}

export default injectIntl(FAQs);