import React, { useEffect, useState } from "react";
import Image from '../elements/Image';
import Checkbox from '../elements/Checkbox';

import { FormattedHTMLMessage } from 'react-intl';

const MATICSimulator = () => {
  const [ task1, setTask1 ] = useState(true);
  const [ task2, setTask2 ] = useState(false);
  const [ task3, setTask3 ] = useState(false);
  const [ task4, setTask4 ] = useState(false);
  const [ task5, setTask5 ] = useState(false);

  let level = 0;
  if (task1) level ++;
  if (task2) level ++;
  if (task3) level = 3;
  if (task4) level ++;
  if (task5) level ++;

  useEffect(() => {
    // Reset
    if (!task1 && !task2 && !task3) {
      setTask4(false);
      setTask5(false);
    }
  }, [task1, task2, task3, task4, task5])

  return (
    <div className="matic-simulator">
      <h4><FormattedHTMLMessage id="giveaway2.simulator.title" /></h4>
      <div>
        <p>
          <Image
            src={require(`../../assets/images/giveaways/Campaign-${level}.gif`)}
            alt="Open"
            width={150}
            height={150} />
          <FormattedHTMLMessage id={`giveaway.level${level}`}/>
        </p>
        <ul>
          <li>
            <Checkbox name="task1" disabled={task3} checked={task1} onClick={(e) => { setTask1(e.target.checked) }}>
              <FormattedHTMLMessage id="giveaway2.simulator.task-1" />
            </Checkbox>
          </li>
          <li>
            <Checkbox name="task2" disabled={task3} checked={task2} onClick={(e) => { setTask2(e.target.checked) }}>
              <FormattedHTMLMessage id="giveaway2.simulator.task-2" />
            </Checkbox>
          </li>
          <li>
            <Checkbox name="task3" checked={task3} onClick={(e) => { setTask3(e.target.checked) }}>
              <FormattedHTMLMessage id="giveaway2.simulator.task-3" />
            </Checkbox>
          </li>
          <li>
            <Checkbox name="task4" disabled={!task1 && !task2 && !task3} checked={task4} onClick={(e) => { setTask4(e.target.checked) }}>
              <FormattedHTMLMessage id="giveaway2.simulator.task-4" />
            </Checkbox>
          </li>
          <li>
            <Checkbox name="task4" disabled={!task1 && !task2 && !task3} checked={task5} onClick={(e) => { setTask5(e.target.checked) }}>
              <FormattedHTMLMessage id="giveaway2.simulator.task-5" />
            </Checkbox>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default MATICSimulator;
