import { ethers, Contract } from 'ethers';

import BabiesContract from './contracts/Rocbabies.json';
import * as Sentry from "@sentry/react";

const networkAddress = "4";
const contractAddress = '0xd59f666E846Ece66d34302796DD1667F70d433e1';

export const connectNetwork = async () => {
  let readContract;
  if(window.ethereum) {
    const provider = new ethers.providers.JsonRpcProvider('https://rinkeby.infura.io/v3/5df738399b4f4a969eaff48455f7e0c8')
    try {
      readContract = new Contract(
        contractAddress,
        BabiesContract.abi,
        provider
      );
    } catch(e) {
      console.error(e.message);
      return { error: true }
    }
    return { readContract };
  }
}

export const connectWallet = async () => {
  let babiesContract;
  if (typeof window.ethereum !== 'undefined') {
    await window.ethereum.request({ method : 'eth_requestAccounts'}).catch(console.error);

    let isWrongNetwork;
    if (typeof window.ethereum.networkVersion !== 'undefined' && networkAddress !== window.ethereum.networkVersion) {
      isWrongNetwork = true;
    }
    let provider;
    let signer;
    try {
      provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      signer = provider.getSigner();
    } catch (e) {
      return { error: true }
    }
    provider.on("network", (newNetwork, oldNetwork) => {
      if (oldNetwork) {
        console.log(newNetwork);
        window.location.reload();
      }
    });

    try {
      babiesContract = new Contract(
        contractAddress,
        BabiesContract.abi,
        signer
      );
    } catch(e) {
      console.error(e.message);
      return { error: true }
    }
    const address = await signer.getAddress();
    const balance = await provider.getBalance(address);

    Sentry.setContext("walletInfo", {
      network: window.ethereum.networkVersion,
      metamask: window.ethereum.isMetaMask,
      address,
      balance: ethers.utils.formatEther(balance),
    });

    Sentry.captureMessage("Connect Wallet", {
      level: "info",
    });

    return { address, babiesContract, isWrongNetwork };
  }
}

export const getBlockchain = () =>
  new Promise((resolve, reject) => {
    window.addEventListener('load', async () => {
      try {
        const readContract = await connectNetwork();
        resolve(readContract);
      } catch(e){
        reject(e.message)
      }
    });
  });

export const getContract = () =>
new Promise((resolve, reject) => {
  window.addEventListener('load', async () => {
    try {
      const babiesContract = await connectWallet();
      resolve(babiesContract);
    } catch(e){
      reject(e.message)
    }
  });
});