// import VConsole from 'vconsole';

import { ethers } from 'ethers';
import React, { useRef, useState, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import Input from '../src/components/elements/Input';
import Button from '../src/components/elements/Button';
// import ReactGA from 'react-ga';
import 'react-notifications/lib/notifications.css';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Terms from './views/Terms';
import FAQs from './views/FAQs';
import Owners from './views/Owners';
import Giveaway from './views/Giveaway';
import { getBlockchain } from './ethereum';

// Initialize Google Analytics
// ReactGA.initialize('G-WHH17WWE3J');

// const trackPage = page => {
//   ReactGA.set({ page });
//   ReactGA.pageview(page);
// };

// const vConsole = new VConsole();
// console.log(vConsole);

const App = (props) => {
  const [babiesReadContract, setBabiesReadContract] = useState(false);
  const [contractInfo, setContractInfo] = useState(false);
  const [glitch, setGlitch] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [password, setPassword] = useState('');
  const { startSales, startGiveaways, mobile } = props;

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    
    if (authorized) {
      document.body.classList.add('is-loaded')
      childRef.current.init();

      window.gtag('event', 'page_view', {
        page_title: page.replace('/', '').toUpperCase(),
        page_location: page.replace('/', ''),
        page_path: page,
        send_to: 'G-WHH17WWE3J'
      })

    } else {
      const auth = localStorage.getItem('auth');
      if (auth === 'fffc3fb5d709a17a21f198a7f13b633b') {
        setAuthorized(true);
      }
      setInitialLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, authorized]);

  // Connect to Ethereum
  useEffect(() => {
    
    const init = async () => {
      if (!startSales) return;
      // Read Contract
      try {
        const { readContract } = await getBlockchain();
        if (readContract) {
          setBabiesReadContract(readContract);
        }
      } catch(e) {
        console.error(e.message);
      }
    };
    init();

  }, [location, startSales]);

  // Update Contract Information
  useEffect(() => {
    if (!startSales) return;
    
    const query = async () => {
      setGlitch(babiesReadContract && !babiesReadContract.totalSupply)
      if (babiesReadContract && babiesReadContract.totalSupply && babiesReadContract.calculatePriceForToken && babiesReadContract.MAX_BABIES) {
        try {
          const totalSupply = await babiesReadContract.totalSupply();
          const maximumBabies = await babiesReadContract.MAX_BABIES();
          const hasSaleStarted = await babiesReadContract.hasSaleStarted();

          let currentPrice = 0;
          if (totalSupply < maximumBabies - 1) {
            currentPrice = await babiesReadContract.calculatePriceForToken(Number(totalSupply.toNumber()));
          }
          
          setContractInfo({
            totalSupply: totalSupply.toNumber(),
            currentPrice: ethers.utils.formatUnits(currentPrice),
            hasSaleStarted,
            maximumBabies: maximumBabies.toNumber() - 1
          })
        } catch(e) {
          console.error(e.message);
        }
      }
    };
    query();

    // let interval;
    // if (babiesReadContract && !interval) {
    //   interval = setInterval(() => {
    //     query();
    //   }, 15000);
    // }
    // return () => clearInterval(interval);

  }, [babiesReadContract, startSales]);

  if (!authorized && !initialLoad) {
    return null;
  }

  if (!authorized) {
    return (
      <ScrollReveal
      ref={childRef}
      children={() => (
        <div
          id="auth"
          ref={childRef}
        >
          <div>
            <Input
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            >
            </Input>
            <Button
                color="primary"
                onClick={async () => {
                  if (password === 'rocketbabies') {
                    setAuthorized(true);
                    localStorage.setItem('auth', 'fffc3fb5d709a17a21f198a7f13b633b');
                  }
                }}
              >
                Auth
            </Button>
          </div>
        </div>
      )} />
    )
  }

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" mobile={mobile} glitch={glitch} babiesReadContract={babiesReadContract} startGiveaways={startGiveaways} startSales={startSales} contractInfo={contractInfo} component={Home} layout={LayoutDefault} />
          { startGiveaways && <AppRoute exact path="/giveaways" startGiveaways={startGiveaways} component={Giveaway} layout={LayoutDefault} /> }
          <AppRoute exact path="/faqs" startGiveaways={startGiveaways} layout={LayoutDefault} component={FAQs}  />
          <AppRoute exact path="/terms" startGiveaways={startGiveaways} component={Terms} layout={LayoutDefault} />
          <AppRoute exact path="/owners" startGiveaways={startGiveaways} startSales={startSales} component={Owners} layout={LayoutDefault} />
        </Switch>
      )} />
  );
}

export default App;