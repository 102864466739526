import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
// import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Button from '../elements/Button';
import StepProgressBar from '../elements/StepProgressBar';
import { FormattedHTMLMessage, injectIntl } from 'react-intl'
import AppContext from '../../AppContext';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const Features1 = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  intl,
  startGiveaways,
  mobile,
  ...props
}) => {

  const [videoModalActive, setVideoModalActive] = useState(false);
  const [videoModalActive2, setVideoModalActive2] = useState(false);
  const { contractInfo } = useContext(AppContext);

  const closeModal = (e) => {
    e.preventDefault();
    setVideoModalActive(false)
    setVideoModalActive2(false)
  }

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner',
    // topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={{ title: intl.formatMessage({ id:'presale.title' }) }} className="center-content" />
          <div className="mb-80 reveal-from-bottom">
            <div>
              <p><FormattedHTMLMessage id="presale.desc" /></p>
            </div>
            <div className="mr-8 before-after">
                <p>
                  <Image
                    src={require(`../../assets/images/incubator.png`)}
                    alt="Open"
                    width={200}
                    height={200} />
                    <FormattedHTMLMessage id="before" />
                </p>
                <p className="arrow">→</p>
                <p>
                  <Image
                    src={require(`../../assets/images/babies/random.gif`)}
                    alt="Open"
                    width={200}
                    height={200} />
                    <FormattedHTMLMessage id="after" />
                </p>
            </div>
          </div> */}
          
          {/* <div id="prices" className="mb-80 reveal-from-bottom">
            <h3><FormattedHTMLMessage id="prices" /></h3>
            <div>
              <p><FormattedHTMLMessage id="prices.desc" /></p>
              <ul className="current-price">
                <li className={`${contractInfo && contractInfo.totalSupply > 4999 ? '': 'active'}`}>
                  <span className="title"><FormattedHTMLMessage id="prices.title1" /></span>
                  <p>
                    <Image
                      src={require(`../../assets/images/early-baby.gif`)}
                      alt="Open"
                      width={100}
                      height={100} />
                    <span className="price"><FormattedHTMLMessage id="prices.1" /></span>
                  </p>
                </li>
                <li className={`${contractInfo && contractInfo.totalSupply > 4999 ? 'active': ''}`}>
                  <span className="title"><FormattedHTMLMessage id="prices.title2" /></span>
                  <p>
                    <Image
                      src={require(`../../assets/images/late-baby.gif`)}
                      alt="Open"
                      width={100}
                      height={100} />
                    <span className="price"><FormattedHTMLMessage id="prices.2" /></span>
                  </p>
                </li>
              </ul>
              <p className="text-color-warning"><FormattedHTMLMessage id="prices.3" /></p>
            </div>
          </div> */}

          {<div id="milestone">
            {/* <SectionHeader data={{ title: intl.formatMessage({ id:'test' }) }} className="center-content" /> */}
            <div className="mb-80">
              <h3><FormattedHTMLMessage id="milestone.title" /></h3>
              <div className="mb-40">
                <p><FormattedHTMLMessage id="milestone.desc" /></p>
              </div>
              {contractInfo && <div>
                <p className="adopted"><FormattedHTMLMessage id="milestone.current" values={{ num: contractInfo.totalSupply }} /></p>
                { !mobile && <StepProgressBar contractInfo={contractInfo} /> }
              </div>}
              <ul className="milestone reveal-from-bottom">
                <li><FormattedHTMLMessage id="milestone.0" /></li>
                <li><FormattedHTMLMessage id="milestone.1" /></li>
                <li><FormattedHTMLMessage id="milestone.2" /></li>
                <li><FormattedHTMLMessage id="milestone.3" /></li>
                <li><FormattedHTMLMessage id="milestone.4" /></li>
              </ul>
            </div>
          </div>}

          <div className="split-item-content center-content-mobile reveal-from-right mb-80">
            <h3 className="mt-0 mb-12">
              <FormattedHTMLMessage id="about4.title" />
            </h3>
            <p className="m-0 doodle-image">
              <Image
                src={require('./../../assets/images/doodle.jpg')}
                alt="Doodle"
                width={200}
                height={200} />
              <FormattedHTMLMessage id="about4.desc" />
            </p>
          </div>

          {/* <div id="timeline" className={innerClasses}>
            <div className="mb-80">
              <h4><FormattedHTMLMessage id="timeline.title" /></h4>
              <div>
                <p><FormattedHTMLMessage id="timeline.desc" /></p>
              </div>
              <ul className="timeline reveal-from-bottom">
                <li><FormattedHTMLMessage id="timeline.1" /></li>
                <li><FormattedHTMLMessage id="timeline.2" /></li>
                <li><FormattedHTMLMessage id="timeline.3" /></li>
              </ul>
            </div>
          </div> */}

          <div className="mt-50">
            {!mobile && <div className="reveal-from-bottom">
              <h3><FormattedHTMLMessage id="howtobuy.title" /></h3>
              <p>
              <Button inline size="sm" onClick={() => {
                window.gtag('event', 'video', {
                  'event_category' : 'engagement',
                  'event_label' : 'Watch Video'
                })
                setVideoModalActive(true)
              }}>
                <Image width="300" src={require(`../../assets/images/yt.jpg`)} />
                {/* <FormattedHTMLMessage id="howtobuy.desc" /> */}
              </Button>
              </p>
              <ol>
                <li><FormattedHTMLMessage id="howtobuy.1" /></li>
                <li><FormattedHTMLMessage id="howtobuy.2" /></li>
                <li><FormattedHTMLMessage id="howtobuy.3" /></li>
                <li><FormattedHTMLMessage id="howtobuy.4" /></li>
                <li><FormattedHTMLMessage id="howtobuy.5" /></li>
                <li>
                  <FormattedHTMLMessage id="howtobuy.6" /><br/>
                  <div className="mr-8 before-after">
                    <p>
                      <Image
                        src={require(`../../assets/images/incubator.png`)}
                        alt="Open"
                        width={150}
                        height={150} />
                        <FormattedHTMLMessage id="before" />
                    </p>
                    <p className="arrow">→</p>
                    <p>
                      <Image
                        src={require(`../../assets/images/babies/random.gif`)}
                        alt="Open"
                        width={150}
                        height={150} />
                        <FormattedHTMLMessage id="after" />
                    </p>
                  </div>
                </li>
              </ol>
              {/* <p className="text-color-warning"><FormattedHTMLMessage id="howtobuy.warn" /></p> */}
            </div>}

            {mobile && <div className="mb-80 reveal-from-bottom">
              <h3><FormattedHTMLMessage id="howtobuyM.title" /></h3>
              <p>
              <Button inline size="sm" onClick={() => {
                window.gtag('event', 'video', {
                  'event_category' : 'engagement',
                  'event_label' : 'Watch Video'
                })
                setVideoModalActive2(true)
              }}>
                <Image width="300" src={require(`../../assets/images/yt2.jpg`)} />
                {/* <FormattedHTMLMessage id="howtobuy.desc" /> */}
              </Button>
              </p>
              <ol>
                <li><FormattedHTMLMessage id="howtobuyM.1" /></li>
                <li><FormattedHTMLMessage id="howtobuyM.2" /></li>
                <li><FormattedHTMLMessage id="howtobuyM.3" /></li>
                <li><FormattedHTMLMessage id="howtobuyM.4" /></li>
                <li><FormattedHTMLMessage id="howtobuyM.5" /></li>
                <li><FormattedHTMLMessage id="howtobuyM.6" /></li>
                <li><FormattedHTMLMessage id="howtobuyM.7" /></li>
                <li>
                  <FormattedHTMLMessage id="howtobuyM.8" />
                  <div className="mr-8 before-after">
                    <p>
                      <Image
                        src={require(`../../assets/images/incubator.png`)}
                        alt="Open"
                        width={150}
                        height={150} />
                        <FormattedHTMLMessage id="before" />
                    </p>
                    <p className="arrow">→</p>
                    <p>
                      <Image
                        src={require(`../../assets/images/babies/random.gif`)}
                        alt="Open"
                        width={150}
                        height={150} />
                        <FormattedHTMLMessage id="after" />
                    </p>
                  </div>
                </li>
              </ol>
              {/* <p className="text-color-warning"><FormattedHTMLMessage id="howtobuy.warn" /></p> */}
            </div>}

          </div>
        
          <div className="mt-50">
            {/* <SectionHeader data={{ title: intl.formatMessage({ id:'test' }) }} className="center-content" /> */}
            <div className="mb-80">
              <h3><FormattedHTMLMessage id="aboutus.title" /></h3>
              <div>
                <p><FormattedHTMLMessage id="aboutus.desc" /></p>
              </div>
              <div className="about-us reveal-from-bottom">
                <p>
                  <Image
                    src={require('./../../assets/images/babies/rocdad.gif')}
                    alt="Roc Dad"
                    width={120}
                    height={120} />
                    <FormattedHTMLMessage id="aboutus.rocdad" />
                  </p>
                  <p>
                    <Image
                      src={require('./../../assets/images/babies/rocuncle.gif')}
                      alt="Roc Uncle"
                      width={120}
                      height={120} />
                    <FormattedHTMLMessage id="aboutus.rocuncle" />
                  </p>
                  <p>
                    <Image
                      src={require('./../../assets/images/babies/rocbaby.gif')}
                      alt="Roc Baby"
                      width={120}
                      height={120} />
                    <FormattedHTMLMessage id="aboutus.rocbaby" />
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="video-modal"
        show={videoModalActive}
        handleClose={closeModal}
        video="https://www.youtube.com/embed/GU-OvxAscrI?rel=0"
        videoTag="iframe" />
      <Modal
        id="video-modal-mobile"
        show={videoModalActive2}
        handleClose={closeModal}
        video={require("../../assets/images/mobile.mp4")}
        />
    </section>
  );
}

Features1.propTypes = propTypes;
Features1.defaultProps = defaultProps;

export default injectIntl(Features1);