import React from "react";
import "react-step-progress-bar/styles.css";
import Image from './Image';
import { ProgressBar, Step } from "react-step-progress-bar";
import { FormattedHTMLMessage } from 'react-intl';

class StepProgressBar extends React.Component {
  render() {
    const { contractInfo } = this.props;
    if (!contractInfo) return null;
    const per = contractInfo.totalSupply/10000*100;
    const soldout = contractInfo.totalSupply === 9999;
    return (
      <ProgressBar
        percent={per}
        stepPositions={[0, 25, 50, 75, 100]}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
      >
        <Step transition="scale">
          {({ accomplished }) => (
            <p style={{ filter: `alpha(${accomplished ? 50 : 100}%)` }}>
              <FormattedHTMLMessage id="milestone.m0" /> <br/>
              <Image
                style={{ filter: `grayscale(${accomplished ? 0 : 100}%)`, opacity: `${accomplished ? 1 : 0.7}` }}
                src={require(`../../assets/images/step1.gif`)}
                alt="Open"
                width={50}
                height={50} />
            </p>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <p style={{ filter: `alpha(${accomplished ? 50 : 100}%)` }}>
              <FormattedHTMLMessage id="milestone.m1" /> <br/>
              <Image
                style={{ filter: `grayscale(${accomplished ? 0 : 100}%)`, opacity: `${accomplished ? 1 : 0.7}` }}
                src={require(`../../assets/images/step2.gif`)}
                alt="Open"
                width={50}
                height={50} />
            </p>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <p style={{ filter: `alpha(${accomplished ? 50 : 100}%)` }}>
              <FormattedHTMLMessage id="milestone.m2" /> <br/>
              <Image
                style={{ filter: `grayscale(${accomplished ? 0 : 100}%)`, opacity: `${accomplished ? 1 : 0.7}` }}
                src={require(`../../assets/images/step3.gif`)}
                alt="Open"
                width={50}
                height={50} />
            </p>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <p style={{ filter: `alpha(${accomplished ? 50 : 100}%)` }}>
              <FormattedHTMLMessage id="milestone.m3" /> <br/>
              <Image
                style={{ filter: `grayscale(${accomplished ? 0 : 100}%)`, opacity: `${accomplished ? 1 : 0.7}` }}
                src={require(`../../assets/images/step4.gif`)}
                alt="Open"
                width={50}
                height={50} />
            </p>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <p>
              <FormattedHTMLMessage id="milestone.m4" /> <br/>
              <Image
                style={{ filter: `grayscale(${(accomplished || soldout) ? 0 : 100}%)`, opacity: `${(accomplished || soldout) ? 1 : 0.7}` }}
                src={require(`../../assets/images/step5.gif`)}
                alt="Open"
                width={50}
                height={50} />
            </p>
          )}
        </Step>
      </ProgressBar>
    );
  }
}
export default StepProgressBar;
