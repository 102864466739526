import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FollowAt from "../elements/react-social-media-follow";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="fixed-footer" data-reveal-delay="600">
        <div className="copy">
          <p>© RocBabies, 2021</p>
        </div>
        <div className="followat">
          <FollowAt links = {[
            'https://twitter.com/rocbabies',
            'https://discord.gg/zE78aDppkG',
          ]}/>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;