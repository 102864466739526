import React from 'react';
import Hero from '../components/sections/Hero';
import Features1 from '../components/sections/Features1';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import { NotificationContainer } from 'react-notifications';
import AppContext from '../AppContext';

const Home = (props) => {
  const { mobile, startSales, startGiveaways, contractInfo, babiesReadContract, glitch } = props;
  return (
    <AppContext.Provider value={{
      contractInfo,
      glitch
    }}>
      <NotificationContainer/>
      <Hero mobile={mobile} startGiveaways={startGiveaways} startSales={startSales} babiesReadContract={babiesReadContract} className="illustration-section-01" />
      <FeaturesSplit mobile={mobile} startGiveaways={startGiveaways} invertMobile imageFill className="what-is-rocbabies" />
      <Features1 mobile={mobile} startGiveaways={startGiveaways} invertMobile topDivider className="what-is-rocbabies" />
      {/* <Features2 invertMobile topDivider className="what-is-rocbabies" /> */}
    </AppContext.Provider>
  );
}

export default Home;